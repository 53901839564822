<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="isUpdate?$t('discounts.update_btn'):$t('discounts.new_btn')"
        :paths="[{title:$t('discounts.title'),link:'discounts'}]"
    />
    <section class="wameed-dashboard-page-content_body coupons_create">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <wameed-form @onSubmit="submit">
            <div slot-scope="{ invalid }">
              <b-card no-body class="wameed-card mb-5">
                <b-card-header class="align-items-start tab-title">
                  <h5 class="text-med-20 text-font-main mb-0">
                    {{ $t('discounts.info') }}
                    <span class="text-main">{{ subHeading }}</span>
                  </h5>
                </b-card-header>
                <b-card-body
                    class="position-relative justify-content-between mt-5 px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                          :readonly="isUpdate"
                          id="name"
                          v-model="form.name"
                          :label="$t('discounts.form.name')"
                          rules="required"
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <wameed-dropdown
                          :disabled="canChangeVendor"
                          :searchable="true"
                          v-model="form.apply_for"
                          :label="$t('discounts.form.apply_for')"
                          :placeholder="$t('form.text.placeholder')"
                          variant="disable"
                          :items="getApplyFor"
                          title="name"
                          :multiple="true"
                          @selectingOption="resetProducts"
                          class="wameed_dropdown"
                          rules="required"
                          :no_options="$t('common.no_options')"
                          :loadingMore="$t('common.loadingMore')"
                          :no_options_search="$t('common.no_options_search')"
                          @setSelectAll="whenSelectAll"
                          :selectAll="selectAll"
                          :selected-all-text="$t('form.all.selected')"
                          :select-all-check-box="$t('form.all.checkBox')"
                      />
                    </b-col>

                    <b-col cols="12" md="4" v-if="canSelectProducts">

                      <div class="wameed-input text-reg-14">
                        <label class="text-font-main text-reg-14 "
                               style="margin-bottom: 8px;">{{ $t('discounts.form.products') }}</label>
                        <treeselect
                            v-model="form.products"
                            :multiple="true"
                            :options="products"
                            :limit="1"
                            :limit-text="(count)=>('+'+count)"
                            :loadingText="$t('common.loadingText')"
                            :noChildrenText="$t('common.noChildrenText')"
                            :noOptionsText="$t('common.noOptionsText')"
                            :noResultsText="$t('common.noResultsText')"
                            :load-options="loadVendorProducts"
                            :default-expand-level="1"
                            :auto-load-root-options="false"
                            :placeholder="$t('discounts.form.all_products')"
                        >
                          <!--                          <div slot="before-list" >all</div>-->
                        </treeselect>
                      </div>

                    </b-col>

                  </div>

                  <div class="d-flex flex-wrap"
                       v-if="!isProductDiscount"
                  >

                    <b-col cols="12" md="4">
                      <TextInput
                          :readonly="isUpdate"
                          v-model="form.usage_limit"
                          rules="required"
                          field-classes="w-100"
                          :label="$t('discounts.form.usage_limit')"
                          :placeholder="$t('form.text.placeholder')"

                          type="number"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <TextInput
                          :readonly="isUpdate"
                          v-model="form.usage_per_user"
                          rules="required"
                          field-classes="w-100"
                          :label="$t('discounts.form.usage_per_user')"
                          :placeholder="$t('form.text.placeholder')"

                          type="number"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <TextInput
                          :readonly="isUpdate"
                          v-model="form.min_order_price"
                          rules="required"
                          field-classes="w-100"
                          :label="$t('discounts.form.min_order_price')"
                          :placeholder="$t('form.text.placeholder')"
                          type="number"
                      />
                    </b-col>
                  </div>


                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <Wameed-date-picker
                          v-model="form.start_at"
                          placeholder="YYYY-MM-DD"
                          :config='{"type":"string","mask":"YYYY-MM-DD"}'
                          mode="date"
                          :label="$t('discounts.form.start_at')"
                          rules="required"
                          @input="clearExpireDate"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <Wameed-date-picker
                          v-model="form.expire_at"
                          placeholder="YYYY-MM-DD"
                          :config='{"type":"string","mask":"YYYY-MM-DD"}'
                          mode="date"
                          :label="$t('discounts.form.expire_at')"
                          rules="required"
                          :minDate="minExpireDate"
                      />
                    </b-col>


                  </div>


                  <div class="d-flex flex-column"

                       v-if="!isProductDiscount"
                  >

                    <wameed-switch
                        class="coupon-code-switch"
                        :checked="showCouponCode"
                        :disabled="isUpdate"
                        :title="$t('discounts.form.require_coupon')"
                        @onChange="toggleShowCode"
                    />


                    <b-col cols="12" md="4" v-if="showCouponCode">
                      <TextInput

                          :readonly="isUpdate"
                          v-model="form.coupon"
                          rules="required|min:3"
                          field-classes="w-100"
                          :name="$t('discounts.form.code')"
                          :label="$t('discounts.form.code')"
                          :placeholder="$t('form.text.placeholder')"
                          is-append
                          :append-class="'coupon-code-append'"

                          id="couponCode"
                      >

                        <div slot="append-slot" class="coupon-code-copy" @click="copyCode">
                          <o-copy-icon/>
                          <span>{{ $t('discounts.copy') }}</span>
                        </div>
                      </TextInput>
                    </b-col>


                  </div>


                </b-card-body>
              </b-card>
              <b-card no-body class="wameed-card mb-5">
                <b-card-header class="align-items-start tab-title">
                  <h5 class="text-med-20 text-font-main mb-0">
                    {{ $t('discounts.form.on') }}
                  </h5>
                </b-card-header>
                <b-card-body
                    class="position-relative justify-content-between  mt-5 px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap mb-5" style="gap:16px">

                    <button type="button" class="discount_on_btn"
                            v-for="on in discountOnList"
                            :key="on.id"
                            :class="[on.id === form.discount_on_id?'active':'', isUpdate?'disable':'',isProductDiscountAndCanChange?'disable':'',]"
                            @click="()=>changeDiscountOn(on)"
                    >
                      {{ on.name }}
                    </button>
                  </div>

                  <div>


                    <create-discount-type
                        v-if="hasDiscountOnDelivery"
                        :is-update="isUpdate"
                        title="delivery"
                        :show-dis-type="isFreeDeliveryDiscount==false"
                        :divide="true"
                        @input="deliveryDiscountChange"
                        :value="form.delivery_discount"
                    />
                    <create-discount-type
                        v-if="hasDiscountOnOrder"
                        :is-update="isUpdate"
                        title="order"
                        :divide="true"
                        @input="orderDiscountChange"
                        :value="form.order_discount"
                    />
                    <create-discount-type
                        v-if="hasDiscountOnProduct"
                        :is-update="isUpdate"
                        title="product"
                        :divide="true"
                        :max-discount="false"
                        @input="productDiscountChange"
                        :value="form.product_discount"
                    />

                    <hr class="mb-5">

                    <template v-if="!isProductDiscount">
                      <template v-for="(condition,index) in conditionList">
                        <create-discount-condition
                            :key="condition.key"
                            :selected-conditions="form.discount_condition"
                            :is-update="isUpdate"
                            :title="condition.title"
                            :divide="true"
                            @input="discountDiscountCondition"
                            @removeCondition="(k)=>removeDiscountCondition(index )"
                            :data="condition.condition"
                            :value="form.discount_condition.find(x=>x.key===condition.key)"
                        />
                      </template>

                      <div class="center"
                           v-if="getConditions && getConditions.length>0 && getConditions.length>conditionList.length && !isUpdate">

                        <div
                            @click="addCondition"
                            class="outline-link d-inline-block"
                        >
                          <span class="mx-2">{{$t('discounts.add_condition')}}</span>
                          <add-circle-icon color="red" class="mx-2"/>

                        </div>
                      </div>

                    </template>


                    <!--                    <create-discount-condition-->
                    <!--                        v-if="!isProductDiscount"-->
                    <!--                        title="usage"-->
                    <!--                        @input="usageDiscountCondition"-->
                    <!--                        :value="form.usage_condition"-->
                    <!--                    />-->

                    <!--                    <create-discount-condition-->
                    <!--                        v-if="!isProductDiscount"-->
                    <!--                        title="discount"-->
                    <!--                        @input="discountDiscountCondition"-->
                    <!--                        :value="form.discount_condition"-->
                    <!--                    />-->


                  </div>

                </b-card-body>
              </b-card>


              <b-card no-body class="wameed-card">
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="isUpdate?$t('discounts.update_btn'):$t('discounts.new_btn')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>

                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @onClick="goBack"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </b-card>
            </div>
          </wameed-form>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
// import the component
import Treeselect, {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import PageHeader from '@/components/wameed/WameedPageHeader.vue';

import {
  WameedBtn,
  WameedDatePicker,
  WameedDropdown,
  WameedForm,
  wameedNotify,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm'
import {DiscountOn, DiscountOnTranslations} from "@/enums/discountOn.enum";
import {DiscountCategoryEnum} from "@/enums/discountCategory.enum";
import CreateDiscountType from "@/views/pages/discounts/sections/createDiscountType.vue";
import CreateDiscountCondition from "@/views/pages/discounts/sections/createDiscountCondition.vue";


export default {
  components: {
    CreateDiscountCondition,
    CreateDiscountType,
    WameedDatePicker,
    TextInput,
    wameedNotify,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
    Treeselect,
  },
  data() {
    return {
      conditionList: [],
      // define the default value
      // define options
      key: 0,
      heading: "",
      selectAll: false,
      discountOnList: DiscountOnTranslations,
      form: {
        discount_category_id: DiscountCategoryEnum.withoutCode,
        name: '',
        discount_on_id: '',
        on_type: '',
        apply_for: '',
        usage_limit: '',
        usage_per_user: '',
        start_at: '',
        expire_at: '',
        products: null,

        usage_condition: null,
        discount_condition: [],

        coupon: '',

      },


    };
  },


  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(newVal, _) {
        if (newVal.products != null && newVal.products.length > 0) {
          this.form.discount_on_id = DiscountOn.products
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getApplyFor: 'admin/discounts/getApplyFor',
      detail: 'admin/discounts/getDetail',
      products: 'admin/discounts/getProducts',
      getConditions: 'admin/discounts/getConditions',

    }),

    canChangeVendor() {
      return this.isUpdate && this.form.discount_on_id === DiscountOn.products
    },
    canSelectProducts() {
      return this.form.apply_for.length === 1 && this.form.apply_for[0].id !== 0;
    },

    isProductDiscount() {
      return (this.form.products != null && this.form.products.length > 0) || (this.form.discount_on_id === DiscountOn.products);
    },

    isProductDiscountAndCanChange() {
      return (this.form.products != null && this.form.products.length > 0) || (this.isUpdate && this.form.discount_on_id === DiscountOn.products);
    },

    isUpdate() {
      return !!(this.$route.name === 'discounts-update' && this.$route.params.id);

    },

    subHeading() {
      if (this.form.discount_on_id) {
        let heading = this.discountOnList.find(el => el.id === this.form.discount_on_id);
        if (heading) {
          return ' - ' + heading.name;
        }
      }
      return '';
    },

    minExpireDate() {
      let date = this.form.start_at;
      if (!date) {
        date = Date();
      }
      return new Date(date.toString()).toString();
    },

    showCouponCode() {
      return this.form.discount_category_id === DiscountCategoryEnum.code;
    },

    hasDiscountOnDelivery() {
      if (this.form.discount_on_id)
        return (this.form.discount_on_id === DiscountOn.delivery_only || this.form.discount_on_id === DiscountOn.total_order || this.form.discount_on_id === DiscountOn.free_delivery);
      return false;
    },

    isFreeDeliveryDiscount() {
      if (this.form.discount_on_id)
        return (this.form.discount_on_id === DiscountOn.free_delivery);
      return false;
    },

    hasDiscountOnOrder() {
      if (this.form.discount_on_id)
        return (this.form.discount_on_id === DiscountOn.order_only || this.form.discount_on_id === DiscountOn.total_order);
      return false;
    },
    hasDiscountOnProduct() {
      if (this.form.discount_on_id)
        return (this.form.discount_on_id === DiscountOn.products);
      return false;
    },


  },

  methods: {

    removeDiscountCondition( index) {
      console.log('index', index)
      this.form.discount_condition.splice(index, 1);
      this.conditionList.splice(index, 1);
      this.key--;

    },

    addCondition() {
      this.conditionList.push({
        key: this.key,
        condition: null,
        value: null,
      })
      this.key++;
    },
    orderDiscountChange(discount) {
      this.form.order_discount = discount
    },
    deliveryDiscountChange(discount) {
      this.form.delivery_discount = discount
    },
    productDiscountChange(discount) {
      this.form.product_discount = discount
    },
    usageDiscountCondition(discount) {
      this.form.usage_condition = discount
    },
    discountDiscountCondition(discount) {
      // toggle the discount condition if it is already exist
      let index = this.form.discount_condition.findIndex(x => x.key === discount.key);
      if (index !== -1) {
        this.form.discount_condition.splice(index, 1);
      }
      this.form.discount_condition.push(discount)
    },

    async loadVendorProducts({action/*, callback*/}) {
      if (action === LOAD_ROOT_OPTIONS) {
        if (this.canSelectProducts) {
          await this.$store.dispatch('admin/discounts/vendorProducts', this.form.apply_for[0].id)
        }
      }
    },

    async resetProducts() {
      await this.$store.dispatch('admin/discounts/resetProducts')
      this.form.products = null;
    },

    changeDiscountOn(on) {
      if (this.isUpdate)
        return;
      if (this.isProductDiscountAndCanChange && on.id !== DiscountOn.products)
        return;

      this.form.order_discount = null;
      this.form.delivery_discount = null;
      this.form.product_discount = null;

      if (on.id === DiscountOn.free_delivery) {
        this.form.delivery_discount = {
          type: "%",
          discount: "100",
          max_discount: "0",
          talqah_discount: null,
          provider_discount: null
        }
      }
      if (on.id === DiscountOn.products) {
        this.form.discount_category_id = DiscountCategoryEnum.withoutCode;
        this.form.coupon = null;
        this.form.usage_limit = null;
        this.form.usage_per_user = null;
        this.form.discount_condition = [];
        this.form.usage_condition = null;

      }
      this.form.discount_on_id = on.id;

    },

    toggleShowCode() {
      if (this.form.discount_category_id === DiscountCategoryEnum.code) {
        this.form.discount_category_id = DiscountCategoryEnum.withoutCode
        this.form.coupon = null;
      } else {
        this.form.discount_category_id = DiscountCategoryEnum.code
      }
    },

    copyCode() {

      navigator.clipboard.writeText(document.getElementById('couponCode').value).then(() => {
        wameedNotify({title: this.$t('common.copied'), type: 'success'})
      });

    },


    clearExpireDate() {
      if (this.form.start_at > this.form.expire_at) {
        this.form.expire_at = null
      }
    },

    goBack() {
      this.$router.push({name: 'discounts'});
    },

    whenSelectAll() {

      this.selectAll = !this.selectAll;

    },

    submit() {


      if (this.selectAll) {
        this.form.apply_for = [{id: 0, name: 'all'}]
      }
      if (this.$route.name === 'discounts-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/discounts/update', this.form);
      } else {
        this.$store.dispatch('admin/discounts/create', this.form);
      }

    },
  },


  async created() {

    await this.$store.dispatch('admin/discounts/getApplyFor');
    await this.$store.dispatch('admin/discounts/getConditions');
    await this.$store.dispatch('admin/discounts/getUsers');


    if (this.$route.name === 'discounts-update' && this.$route.params.id) {
      this.$store.dispatch("admin/discounts/detail", this.$route.params.id).then(async () => {

        this.form.discount_condition = [];
        this.form = {
          ...this.form,
          ...this.detail,
        };
        this.conditionList = this.detail.discount_condition;
        this.form.products = null;
        //
        if (this.form.apply_for && this.form.apply_for.length > 0) {
          if (this.form.apply_for[0].id == null) {
            this.form.apply_for = [{id: 0, name: 'all'}]
            this.selectAll = true;
          }
        }
        if (this.canSelectProducts) {
          await this.$store.dispatch('admin/discounts/vendorProducts', this.form.apply_for[0].id)

          this.form.products = this.detail.products;
        }
      });
      this.form.start_at = this.detail.start_at;
    } else {
      await this.$store.dispatch("admin/discounts/unsetDetail");
    }

  },
};
</script>

<style>
.vue-treeselect-helper-zoom-effect-off {
  padding-top: 2.5px;
  padding-right: 5px;
}

.vue-treeselect__multi-value-item, .vue-treeselect__limit-tip-text {
  background-color: #bac1cc;
  border-radius: 8px;
  padding: 0px 39px 0 14px;
  color: white;
  position: relative;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 26px !important;

  border: var(--vs-selected-border-width) var(--vs-selected-border-style) var(--vs-selected-border-color);
}

.vue-treeselect__limit-tip-text {
  padding: 0px 14px 0 14px;

}


.vue-treeselect__value-remove {


  position: absolute;
  top: 1px;
  right: 6px;
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi4zNDUiIGhlaWdodD0iMTIuMzQ1IiB2aWV3Qm94PSIwIDAgMTIgMTEiIGNsYXNzPSIiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAxNTA3NzgiIGQ9Ik0xMi4wODQgMTAuNzdsLTQuNi00LjYgNC42LTQuNmEuOTU4Ljk1OCAwIDAwMC0xLjMxNC45NTguOTU4IDAgMDAtMS4zMTQgMGwtNC42IDQuNi00LjYtNC42YS45NTguOTU4IDAgMDAtMS4zMTQgMCAuOTU4Ljk1OCAwIDAwMCAxLjMxNGw0LjYgNC42LTQuNiA0LjZhLjk1OC45NTggMCAwMDAgMS4zMTQuOTU4Ljk1OCAwIDAwMS4zMTQgMGw0LjYtNC42IDQuNiA0LjZhLjk1OC45NTggMCAwMDEuMzE0IDAgLjk1OC45NTggMCAwMDAtMS4zMTR6IiBmaWxsPSIjOGQ5OGFhIj48L3BhdGg+PC9zdmc+");

  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
  border: none;
  color: white;
}

.vue-treeselect__value-remove svg {
  display: none;
  width: 15px;
  height: 15px;
}

.vue-treeselect__multi-value-item:hover .vue-treeselect__value-remove {
  color: white !important;
}


.vue-treeselect__checkbox {
  display: block;
  margin: auto;
  border-width: 1px;
  border-style: solid;
  transition: 200ms all cubic-bezier(0.075, 0.82, 0.165, 1);

//appearance: none; //background-color: #EDF0F5; //display:flex; //align-items:center; //justify-content:center; width: 20px; height: 20px; border-radius: 6px; flex: none; position: relative; transform: translateY(4px);
}

.vue-treeselect__check-mark,
.vue-treeselect__minus-mark {
  position: absolute;
  display: inline-block;
  right: unset;
  top: 5px;
  left: -6px;
  opacity: 0;
//padding: 4px; width: 20px; height: 20px; transition: 200ms all ease;
}

.vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked {
  border-color: #245389;
  background: #fff;
}

.vue-treeselect__checkbox--checked,
.vue-treeselect__checkbox--indeterminate {
  border-color: #245389;
  background: #245389;
}

.vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked,
.vue-treeselect__label-container:hover .vue-treeselect__checkbox--indeterminate {
  border-color: #245389;
  background: #245389;
}

.vue-treeselect__control {

  padding-left: 1rem;
  padding-right: 1rem;
  background-clip: padding-box;
  border: 1px solid rgba(237, 240, 245, 0.4) !important;
  border-radius: 8px;
  box-shadow: unset !important;
  height: 42px;

  display: table;
  table-layout: fixed;
  width: 100%;
  border: unset;
  background: rgba(237, 240, 245, 0.4) !important;;
}

.vue-treeselect__menu {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175) !important;
  border: 0px;
  border-radius: 8px;
  overflow-x: hidden;
}

.vue-treeselect__label {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
}

.vue-treeselect__label-container {
  min-height: 40px;
  display: flex;
  align-items: center;
//justify-content: center;
}

.vue-treeselect__control-arrow-container {
  position: relative;
}

.vue-treeselect__control-arrow-container > svg {
  display: none;
}

.vue-treeselect__control-arrow-container::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICA8ZyBpZD0iR3JvdXBfMTMzNTI0IiBkYXRhLW5hbWU9Ikdyb3VwIDEzMzUyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMjgzKSI+CiAgICA8ZyBpZD0iUmVjdGFuZ2xlXzExMDQzMSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMTEwNDMxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjI4MykiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzcwNzA3MCIgc3Ryb2tlLXdpZHRoPSIxIiBvcGFjaXR5PSIwIj4KICAgICAgPHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiBzdHJva2U9Im5vbmUiLz4KICAgICAgPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgZmlsbD0ibm9uZSIvPgogICAgPC9nPgogICAgPHBhdGggaWQ9IlBhdGhfMTQ3MjU5IiBkYXRhLW5hbWU9IlBhdGggMTQ3MjU5IiBkPSJNLjI1Nyw2LjUxNGw1LjM3OCw1LjFhLjkxLjkxLDAsMCwwLDEuMjM5LDAsLjguOCwwLDAsMCwwLTEuMTc0TDIuMTE0LDUuOTI3bDQuNzU4LTQuNTFhLjguOCwwLDAsMCwwLTEuMTc0LjkxLjkxLDAsMCwwLTEuMjM5LDBMLjI1Niw1LjM0YS44LjgsMCwwLDAsMCwxLjE3NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNDY4IDEyLjUwNSkgcm90YXRlKC05MCkiIGZpbGw9IiNiYWMxY2MiLz4KICA8L2c+Cjwvc3ZnPgo=");

}

.vue-treeselect__single-value {
  line-height: 40px;
}
</style>