const DiscountOn = {
    vendor: 1,
    delivery_only: 2,
    order_only: 3,
    total_order: 4,
    products: 5,
    free_delivery: 6,

}
const DiscountOnType = {
    free: 1,
    destination: 2,
    order_count: 3,
    custom: 4,
}

import i18n from "@/libs/i18n";

const DiscountOnTranslations = [

    {
        id: 6,
        name: i18n.t('discounts.ons.free_delivery'),
    },
    {
        id: 2,
        name: i18n.t('discounts.ons.delivery_only'),
    },
    {
        id: 3,
        name: i18n.t('discounts.ons.order_only'),
    },
    {
        id: 4,
        name: i18n.t('discounts.ons.double_discount'),
    },
    {
        id: 5,
        name: i18n.t('discounts.ons.products'),
    },


];

function filterDiscount(id) {
    return DiscountOnTranslations.find((item) => item.id === id);
}


export {DiscountOn, DiscountOnType,DiscountOnTranslations, filterDiscount};
