<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-wrap" v-if="showDisType">

      <b-col cols="12" md="4">

        <div class="wameed-input text-reg-14">
          <label class="text-font-main text-reg-14 "
                 style="margin-bottom: 8px;">{{ $t('discounts.section.' + title + '.type') }}</label>
          <treeselect
              v-model="form.type"
              :multiple="false"
              :options="discountTypes"
              :limit="1"
              :clearable="false"
              :searchable="false"
              :loadingText="$t('common.loadingText')"
              :noChildrenText="$t('common.noChildrenText')"
              :noOptionsText="$t('common.noOptionsText')"
              :noResultsText="$t('common.noResultsText')"
              :disabled="isUpdate"

              :placeholder="$t('discounts.discounts.placeholder')"
          >
            <!--                          <div slot="before-list" >all</div>-->
          </treeselect>
        </div>
      </b-col>

      <b-col cols="12" md="4">
        <TextInput
            :readonly="isUpdate"
            v-model="form.discount"
            :rules="'required'+maxDiscountValidation"
            field-classes="w-100"
            :label="$t('discounts.section.'+title+'.value')"
            :placeholder="$t('form.text.placeholder')"
            is-append
            :append-text="getValueAppendText"
            type="number"
            @input="change"
        />
      </b-col>

      <b-col cols="12" md="4" v-if="isPercentDiscount && maxDiscount">
        <TextInput
            :readonly="isUpdate"
            v-model="form.max_discount"
            rules="required"
            field-classes="w-100"
            :label="$t('discounts.section.'+title+'.max')"
            :placeholder="$t('form.text.placeholder')"
            is-append
            :append-text="$t('discounts.discounts.append_v')"
            type="number"
            @input="change"
        />
      </b-col>
    </div>


    <div class="d-flex flex-wrap" v-if="divide">
      <b-col cols="12" md="4">
        <TextInput
            :readonly="isUpdate"
            v-model="form.talqah_discount"
            :rules="'required|max_value:'+talqahDiscount+'|min_value:'+talqahDiscount"
            field-classes="w-100"
            :label="$t('discounts.form.talqah_discount_'+title)"
            :placeholder="$t('form.text.placeholder')"
            is-append
            :append-text="getValueAppendText"
            type="number"
            @input="change"
        />
      </b-col>

      <b-col cols="12" md="4">
        <TextInput
            :readonly="isUpdate"
            v-model="form.provider_discount"
            :rules="'required|max_value:'+providerDiscount+'|min_value:'+providerDiscount"
            field-classes="w-100"
            :label="$t('discounts.form.provider_discount_'+title)"
            :placeholder="$t('form.text.placeholder')"
            is-append
            :append-text="getValueAppendText"
            type="number"
            @input="change"
        />
      </b-col>


    </div>
  </div>
</template>

<script>
import {WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm'
import {DiscountTypes} from "@/enums/discountType.enum";

import Treeselect from '@riophae/vue-treeselect'

export default {
  components: {
    TextInput,
    Treeselect
  },
  data() {
    return {
      form: {
        type: null,
        discount: null,
        max_discount: null,
        talqah_discount: null,
        provider_discount: null,
      },
    };
  },
  props: {
    maxDiscount: {
      default: true,
    },
    divide: {
      default: false,
    },
    isUpdate: {
      default: false,
    },
    showDisType: {
      default: true,
    },
    title: {
      default: 'default',
      type: String
    },
    value: Object
  },
  watch: {
    value(newVal) {
      if (newVal != null) {
        this.form = newVal;
      } else {
        this.clear()
      }
    },
  },
  computed: {
    discountTypes() {
      if (this.title === 'product') {
        return DiscountTypes.filter((item) => item.id === '%');
      }
      return DiscountTypes;
    },
    isPercentDiscount() {
      let temp = "";
      if (this.form.type) {
        temp = this.form.type;
      }
      return temp === '%';
    },

    getValueAppendText() {
      let temp = "";
      if (this.form.type) {
        temp = this.form.type;
      }

      if (temp) {
        return this.$i18n.t('discounts.discounts.append_' + temp)
      }
      return this.$i18n.t('discounts.discounts.append_text')
    },
    maxDiscountValidation() {
      if (this.isPercentDiscount) {
        return '|max_value:100|min_value:1';
      }
      return '';
    },
    providerDiscount() {
      return this.form.discount - this.form.talqah_discount;
    },

    talqahDiscount() {
      return this.form.discount - this.form.provider_discount;
    },

  },
  methods: {
    changeDropDown() {
      if (!this.isPercentDiscount) {
        this.form.max_discount = null;
      }
      this.$emit('input', this.form);
    },
    change() {
      this.$emit('input', this.form);
    },

    clear() {
      this.form = {
        type: null,
        discount: null,
        max_discount: null,
        talqah_discount: null,
        provider_discount: null,
      };
    }
  },
  created() {
    if (this.value != null) {
      this.form = this.value;
    }
  },

};
</script>
